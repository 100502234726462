import React, { Component } from 'react';
import {NODE_STANDARDS} from '../utility/constants';

class NodeStandards extends Component{
    constructor(props){
        super(props);
		
        this.state = {
            mappings : []
        };
		
		// Function Bindings
        this.getStandards = this.getStandards.bind(this);
    }

    getStandards() {
        fetch( NODE_STANDARDS + "getStandardsByMap?mapId="+this.props.mapId)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    mappings: result.nodeStandard
                });
            });
    }
	
	componentDidMount() {
		if (this.props.mapId) {
			this.getStandards();
		}
	}

    render(){
        const std = (this.state.mappings.length) 
		? this.state.mappings.map(item => 
			<tr key={ item.standardId ? item.standardId : item.textID } 
                onMouseEnter={() => this.props.highlightNode(item.nodes.map(n => n.nodeId))}
				onMouseLeave={this.props.unHighlightNodes}>
				<td dangerouslySetInnerHTML={{'__html': item.textID}}></td>
				<td dangerouslySetInnerHTML={{ '__html': item.description}}></td>
				<td>{item.nodes.map(n => n.textId).join(', ')}</td>
			</tr>
		) 
		: <tr><td>No Standard to Display</td></tr>;
        
		return (
            <div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>Standard Id</th>
                                <th>Description</th>
                                <th>Nodes</th>
                            </tr>
                            {std}
                        </tbody>
                    </table>
            </div>
        );
    }
}
export default NodeStandards
