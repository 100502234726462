import React, { Component } from 'react';
import {
	NavLink, 
	Link
} from "react-router-dom";
import home_icon from '../../images/ico_home.svg';
import kite_logo_2018 from "../../images/kite_logo_2018.png";
import './NavBar.css';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    
    // Function bindings
  }

  render() {
	// Decide what to call the user
	const userName = this.props.user.displayname
		? this.props.user.displayname
		: this.props.user.firstname 
			? this.props.user.firstname + ' ' + this.props.user.surname
			: this.props.user.username;
	
	return (
		<div style={{ maxWidth: 1050, marginLeft: 'auto', marginRight: 'auto' }} >
			<div className="app-menu-header">
				<Link to="/home">
					<img src={kite_logo_2018} alt="logo" className="app-logo" />
				</Link>
				<span className="signout-right">
					<div className="auth-button">Logged in as {userName}, {" "}
						<Link to="/" style={{ color: "rgb(14, 118, 188)", cursor: "pointer" }} onClick={this.props.logoutFn} >Sign Out</Link>
					</div>
				</span>
			</div>
			<div className="title-bar">
				<NavLink to="/home" exact className="nav-button" activeClassName='active'>
					<img src={home_icon} className='home-icon' alt='homeicon' />
				</NavLink>
				<NavLink to="/visualizer" className="nav-button" activeClassName='active'>Map Visualizer</NavLink>
			</div>
		</div>
	);
  }
}
export default NavBar;
