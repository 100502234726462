import React from 'react';
import LearningMap from './LearningMap.js';
import { SHOW_CONTEXT_MENU, MAP_PORTAL_FLAG, MAP_NODES,EDGES, PRINT_ACTION } from '../utility/constants';
import NodeInfo from './NodeInfo';
import MapLegend from './MapLegend';
import StandardsTable from '../StandardsTable/StandardsTable';
import SideContainer from '../SideContainer/SideContainer';
import loading_gif from '../../images/loading.gif';
import './MapCanvas.css';

class MapCanvas extends React.Component {
	constructor(props) {
		super(props);
				
		// State management:
		this.state = {
			error: null,
			isLoaded: false,
			nodeInfoOpen:false,
			mapNodes: [],
			directEdges: [],
			indirectEdges: [],
			nodeInfo: {},
			sideOpen: false,
			printAction: PRINT_ACTION.NONE,
			highlightedNodes: []
		};
		
		// Function bindings:
		this.fetchMapData = this.fetchMapData.bind(this);
		this.changeNodeColor = this.changeNodeColor.bind(this);
		this.showNodeInfo = this.showNodeInfo.bind(this); 		
		this.toggleNodeInfoDiv = this.toggleNodeInfoDiv.bind(this);
		this.toggleSidePanel = this.toggleSidePanel.bind(this);
		this.triggerPrintAction = this.triggerPrintAction.bind(this);
		this.clearMapId = this.clearMapId.bind(this);
		this.highlightNode = this.highlightNode.bind(this);
		this.unHighlightNodes = this.unHighlightNodes.bind(this);
	}

	clearMapId() {
		this.props.updateMapId(null);
	}
	
	// Get map data from the API
	fetchMapData() {
		return new Promise((resolve, reject) => {
			// First, get nodes
			fetch(MAP_NODES+'selectNodesByMap?mapId=' + this.props.mapId)
			.then(res => res.json())
			.then(
				(result) => {
					if (result.length) {
						let mapTitle = "";
						let nodeIds = result.map((node) => {
							mapTitle = node.map.title;
							return node.node.nodeId;
						});
												
						let directPromise = fetch(EDGES+'getEdgesByNodesList', {
								method: 'POST',
								headers: {
									Accept: 'application/json',
									'Content-Type': 'application/json'
								},
								body: JSON.stringify(nodeIds)
							})
							.then (r => r.json());
					
						//TODO: enable when we make the switch to postgres, 
						//MySQL seems to have performance issues with the 
						//indirect edges queries
						let indirectPromise = false 
						? fetch(EDGES+'getIndirectEdgesByNodesList', {
								method: 'POST',
								headers: {
									Accept: 'application/json',
									'Content-Type': 'application/json'
								},
								body: JSON.stringify(nodeIds)
							})
							.then (r => r.json())
						: [];
						
						// Second, get the direct and indirect edges
						Promise.all([ directPromise, indirectPromise ])
						.then(
							(jsonArray) => {
								this.setState({
									isLoaded: true,
									mapNodes: result,
									mapTitle:mapTitle,
									directEdges: jsonArray[0],
									indirectEdges: jsonArray[1],
									highlightedNodes: []
								}, ()=>{
									resolve();
								});
							},
							
							// Error catch for direct edge fetch
							(error) => {
								this.setState({
									isLoaded: false,
									error: error,
									mapNodes: [],
									directEdges: [],
									indirectEdges: [],
									highlightedNodes: []
								}, () => {reject();});
							}
						);
					}
					else {
						this.setState({
							isLoaded: true,
							error: false,
							mapNodes: [],
							directEdges: [],
							indirectEdges: [],
							highlightedNodes: []
						}, () => {reject();});
					}
				},
				
				// Error catch for node fetch
				(error) => {
					this.setState({
						isLoaded: false,
						error: error,
						mapNodes: [],
						directEdges: [],
						indirectEdges: [],
						highlightedNodes: []
					}, () => {reject();});
				}
			);
		});
	}

	//Fetch and show the edge information in the box in mapCanvas
	showNodeInfo(mapNode){
		if (
			mapNode.id && mapNode.attributes 
			&& mapNode.attributes.attrs && mapNode.attributes.attrs.body
		) {
			let nodeObj = this.state.mapNodes.filter((arrNode) => arrNode.node.nodeId === mapNode.id);
			
			nodeObj = nodeObj.length ? nodeObj[0].node : {};
			
			this.setState({nodeInfo:nodeObj}, () => {
				this.toggleNodeInfoDiv(true);
			});
		}
	}

	toggleNodeInfoDiv(newValue){
		this.setState({				
			nodeInfoOpen: newValue		
		});
	}

	// Handle node color change
	changeNodeColor(mapNode, color) {
		return new Promise((resolve, reject) => {
			if (
				mapNode.id && mapNode.id && mapNode.attributes 
				&& mapNode.attributes.attrs && mapNode.attributes.attrs.body
			) { // Valid node
				let c = 0;

				switch(color) {
					case 'blue':
						c = 2;
						break;
					case 'red':
						c = 1;
						break
					case 'gray':
						c = 0;
						break;
					default:
						c = 0;
				}
				fetch(MAP_NODES+'editMapNode', {
					method: 'PUT',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						color: c,
						map: {
							mapId: this.props.mapId
						},
						node: {
							nodeId: mapNode.id
						}
					})
				})
				.then(
					(result) => {
						this.fetchMapData().then(() => {resolve('Node color successfully changed. Redrawing. ');});
					},
					
					(error) => {
						reject('Error encountered in MapCanvas::changeNodeColor. Aborting. ');
					}
				);
			}
			else {
				reject('Invalid inputs encountered in MapCanvas::changeNodeColor. Aborting. ');
			}
		});
		
	}
	
	// Open/close the left-hand panel
	toggleSidePanel() {
		this.setState((prevState) => {
			return {
				sideOpen: !prevState.sideOpen
			};
		});
	}

	triggerPrintAction(value){
		this.setState({
			printAction: value
		});
	}

	highlightNode(nodeList) {
		this.setState({
			highlightedNodes: nodeList
		});
	}

	unHighlightNodes() {
		this.setState({
			highlightedNodes: []
		});
	}

	// Initial page load
	componentDidMount() {
		if (this.props.mapId) { // Make sure props are valid
			this.fetchMapData();
		}
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.mapId && this.props.mapId !== prevProps.mapId) { // Don't waste time fetching the same data
			this.fetchMapData();
		}
	}
	
	render() {
		// Sanity checking
		if (!this.props.mapId) {
			return ( <StandardsTable updateMapId={this.props.updateMapId} userPreferences={this.props.userPreferences} /> );
		}
		else if (this.state.error) {
			return <div className='error-text'>
				Error: {
					this.state.error.message 
					? this.state.error.message 
					: 'An unknown error has occurred (MC43)'
				}
			</div>;
		} 
		else if (!this.state.isLoaded) { // Display load text until we're ready for user input
			return (
				<div style={{textAlign: 'center'}} >
					Loading <img src={loading_gif} alt='loading gif' style={{ verticalAlign: 'middle' }} />
				</div>);
		}
		
		const mapLegendDiv = this.props.showMapLegend
			? (
				<MapLegend sideOpen={this.state.sideOpen} nodeInfoOpen={this.state.nodeInfoOpen}/>
			)
			: null;
		
		const formattedNodes = this.state.mapNodes.map((node) => {
			return {
				id: node.node.nodeId,
				nodeKey: node.node.textId,
				nodeName: node.node.title,
				nodeDesc: node.node.shortTitle,
				nodeColor: node.color ? node.color : 'gray',
				linkageLabel: false // Not using these in phase 1!
			};
		});
		
		const formattedDirectEdges = this.state.directEdges;
		
		const formattedIndirectEdges = this.props.showIndirectEdges 
			? this.state.indirectEdges
			: [];
		
		const formattedLinkageLevels = {};
		
		// Put it all together:
		return (
			<div style={{display:'flex'}}>
				<SideContainer
					toggleSidePanel={this.toggleSidePanel}
					triggerPrintAction={this.triggerPrintAction}
					printAction={this.state.printAction}
					sideOpen={this.state.sideOpen}
					mapId={this.props.mapId}
					user={this.props.user}
					clearMapId={this.clearMapId}
					highlightNode={this.highlightNode}
					unHighlightNodes={this.unHighlightNodes}
				/>
				<div
					className={'map-canvas-container '}
				>
					{mapLegendDiv}
					<LearningMap
						nodes={formattedNodes}
						mapTitle={this.state.mapTitle}
						directEdges={formattedDirectEdges}
						indirectEdges={formattedIndirectEdges}
						linkageLevels={formattedLinkageLevels}
						showNodeIds={this.props.showNodeIds}
						showContextMenu={SHOW_CONTEXT_MENU}
						changeNodeColor={this.changeNodeColor} 
						showNodeInfo={this.showNodeInfo}
						mapPortalOnly={MAP_PORTAL_FLAG}
						highlightOnHover={this.props.highlightOnHover}
						printAction={this.state.printAction}
						triggerPrintAction={this.triggerPrintAction}
						showZoomButtons={true}
						highlightedNodes={this.state.highlightedNodes}
					/>
					<NodeInfo
						node={this.state.nodeInfo}
						mapId={this.props.mapId}
						sideOpen={this.state.sideOpen}
						toggleNodeInfoDiv={this.toggleNodeInfoDiv}
						nodeInfoOpen={this.state.nodeInfoOpen}
					/>
				</div>
			</div>
		);
	}
}
export default MapCanvas;
