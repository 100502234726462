
export const MAPS = "mapportal/map/";
export const EDGES = "mapportal/edge/";
export const NODES = "mapportal/node/";
export const MAP_NODES = "mapportal/mapnode/";
export const NODE_STANDARDS = "mapportal/nodeStandard/";
export const USERS = "mapportal/user/";
export const USER_ROLES = "mapportal/userRole/";
export const ROLES = "mapportal/role/";
export const ROLES_PERMISSION = "mapportal/rolePermission/";
export const PERMISSIONS = "mapportal/permissions/";
export const PREFERENCES = "mapportal/preferences/";
export const USER_PREFERENCES = "mapportal/userPreferences/";
export const RESOURCES = "mapportal/resource/";
export const MAP_RESOURCE = "mapportal/mapresource/";
export const LOGIN = 'mapportal/login/';
export const STANDARD_SET = "mapportal/standardSet/";
export const SUBJECT = "mapportal/subject/";

export const MAP_INFO_TAB = "MAPINFO";
export const NODE_STD_TAB = "NODESTD";
export const RESOURCES_TAB = "RESOURCES";
export const NODE_TABLE_TAB = "NODETABLE";

export const PREFERENCE_MENU = "preferences";
export const PREF_MENU_LINK = "PreferencesLink";

export const SHOW_CONTEXT_MENU = 1; //keeping this here considering Dashboard wont need context menu
export const MAP_PORTAL_FLAG = 1; //to add some Map-portal only configuration to the learningMap.js
export const GRADEDOMAIN = "mapportal/gradeDomain/";
export const STANDARD = "mapportal/standard/";

export const PRINT_ACTION = {
    NONE:'none',
    JPEG:'jpeg',
    PNG:'png',
    PRINT:'print'
};

