import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Link
} from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import MapCanvas from './components/MapCanvas/MapCanvas.js';
import PreferenceMenu from './components/PreferenceMenu/PreferenceMenu.js';
import home_background from './images/home_background.jpg';
import myprofile from './images/myprofile.png';
import { LOGIN, PREFERENCES, USER_PREFERENCES, USER_ROLES, STANDARD_SET, SUBJECT } from './components/utility/constants';
import {
	translatePreferenceValue, 
	getUserPreference, 
	SHOW_NODE_IDS_PREF, 
	SHOW_INDIRECT_CONNECTIONS_PREF, 
	SHOW_MAP_LEGEND_PREF, 
	SHOW_MAP_HIGHLIGHTING_ON_HOVER_PREF, 
	STANDARD_SET_PREF, 
	STATE_PREF, 
	DEFAULT_SUBJECT_PREF
} from './components/utility/UserPreferenceUtil.js';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

// Primary component for the application. Handles page routing, user management
class App extends Component {
	constructor(){
		super();
		
		// get arguments from URL
		let parsedUrl = new URL(window.location.href);
		let userAuditId = parsedUrl.searchParams.get('uaid');
		let userOrgId = parsedUrl.searchParams.get('ogid');
		let assessmentProgramId = parsedUrl.searchParams.get('apid');
		let userGrpId = parsedUrl.searchParams.get('gid');
		
		// this is to store the logged in user details, and Selected MapId information
		this.state = {
			isAuthenticated: false,
			userAuditId: userAuditId,
			userOrgId: userOrgId,
			assessmentProgramId: assessmentProgramId,
			userGrpId: userGrpId,
			epURL: '', // If the call to get this fails, relative links should just log the user out
			activeRole: {},
			user: false,
			mapId : null, //update on selecting mapId
			preferences : {},
			userPreferences : [],
			subjects : [],
			standardSets : []
		};
		
		// Function bindings
		this.updateMapId = this.updateMapId.bind(this);
		this.epRoleChangeFn = this.epRoleChangeFn.bind(this);
		this.newSession = this.newSession.bind(this);
		this.setSessionStorage = this.setSessionStorage.bind(this);
		this.getSessionStorage = this.getSessionStorage.bind(this);
		this.logoutFn = this.logoutFn.bind(this);
		this.getEPURL = this.getEPURL.bind(this);
		this.updateUserPreferenceOnSubmit = this.updateUserPreferenceOnSubmit.bind(this);
	}

	updateMapId(mapId){
		this.setState({
			mapId:mapId
		});
	}
	
	updateUserPreferenceOnSubmit(changed_pref_details){
		let updatedPreferences = [];
		Promise.all(
			changed_pref_details.map((preference) =>
				fetch(
					USER_PREFERENCES + "addUserPreference", 
					{
						method: 'POST',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
								"isActive": true,
								"preference": {
									"preferenceId": preference.preferenceId //capture all changes on the drop down
								},
								"user": {
									"userId": this.state.user.id
								},
								"value" : preference.pref_val
						})
					}
				).then((response) => {
					if (response.status === 200) {
						return response.json();
					}
					else {
						let userPreference = getUserPreference(this.state.userPreferences, preference.id);
						userPreference.value = userPreference.preference.defaultValue;
						updatedPreferences.push(userPreference);
					}
				})
			)
		)
		.then((responses) => {
			
			responses.forEach((response,index) => {
				//Collect the changes so we can merge them back into the state
				//a 200 means we might have to update the entities associated with 
				//standard set, state and subject.  If it's not a 200, I'm falling back
				//to whatever the default value is in the preference definition. 
				let userPreference = getUserPreference(this.state.userPreferences, response.preference.preferenceId);
				userPreference.value = response.value;
				if(userPreference.preference.preferenceId === STATE_PREF || userPreference.preference.preferenceId === STANDARD_SET_PREF){
					let stateName = translatePreferenceValue(userPreference);
					userPreference.entity = this.state.standardSets.filter(standardSet => standardSet.name === stateName || (standardSet.name.indexOf("CCSS") && stateName.indexOf("CCSS")) )[0];
				}else if(userPreference.preference.preferenceId === DEFAULT_SUBJECT_PREF) {
					let subjectName = translatePreferenceValue(userPreference);
					userPreference.entity = this.state.subjects.filter(subject => subject.name === subjectName)[0];
				}
				updatedPreferences.push(userPreference);
			});

			//Merge the updated preferences back into the state. 
			const newPrefs = this.state.userPreferences.map(up =>  {
				let updatedPref = updatedPreferences.find(p => p.preference.preferenceId === up.preference.preferenceId);
				return updatedPref ? updatedPref : up;
			});
			
			this.setState({
				userPreferences : newPrefs
			}, () => {
				if (sessionStorage) {
					sessionStorage.setItem('userPreferences', JSON.stringify(this.state.userPreferences));
			}});
		});
	} 
	
	// Change the user's active Organization from EP
	epRoleChangeFn(newRole) {
		this.setState({activeRole: newRole});
	}
	
	// Transfer an active session from EP
	newSession() {
		fetch(
			LOGIN + 'transfer/' + this.state.userAuditId + '/' + this.state.userOrgId + '/' + this.state.assessmentProgramId + '/' + this.state.userGrpId, 
			{
				method: 'GET'
			}
		)
		.then(res => res.json())
		.then(
			(result) => { // We got a response from the server!
				if ( result.orgs && result.orgs.length >= 1 ) { // And it had an active session!
					let data = result;
					data.storageTimeout = Date.now();
					
					//We need to call a bunch of services here, so lets do them all at once
					Promise.all([
						fetch(PREFERENCES + "getAllPreferences").then(r => r.json()), 
						fetch(USER_PREFERENCES + 'getAllPreferencesForUser?userId=' + data.id, { method: 'GET'}).then(r => r.json()),
						fetch(USER_ROLES + 'getAllRolesForUser?userId=' + data.id, { method: 'GET'}).then(r => r.json()),
						fetch(STANDARD_SET + 'getAllStandardSets', { method: 'GET'}).then(r => r.json())
					]).then((jsonArray) => {
					
						let allPrefsRes = jsonArray[0];
						let userPrefRes = jsonArray[1];
						let roleRes = jsonArray[2];
						let standardSetRes = jsonArray[3];
						let userPrefs = [];

						/*A couple of the user preferences are problematic: 
						subject and standard set are both entities in their own right
						and other parts of the app will need their respective IDs to 
						make API calls. Unfortunately, there's no apparent nice mapping
						between them, so we're doing it manually by name.
						Also, state and standard set appear to be similar,
						but state doesn't appear to have a corresponding representation 
						on the server side. We'll use standard set in the meantime
						*/
						
						/*The getUserPreferences call only returns preferences that have been
						explicitly persisted to the database. We're going to cheat a little and manually
						filling in any missing ones using the preference definitions. 
						*/
						allPrefsRes.forEach(preference => {
							let userPreference = userPrefRes.find(up => up.preference.preferenceId === preference.preferenceId);
							
							if(!userPreference) {
								userPreference = {user: {userId : data.id}, preference: preference, value : preference.defaultValue};
							}
							userPreference.user = {userId : data.id};

							//try to patch in entities where appropriate.
							if(userPreference.preference.preferenceId === STANDARD_SET_PREF){
								let prefVal = translatePreferenceValue(userPreference);

								standardSetRes.forEach((standardSet) =>{
									if(standardSet.name === prefVal || (prefVal.indexOf("CCSS") >= 0 && standardSet.name.indexOf("CCSS") >= 0)) {
										userPreference.entity = standardSet;
									}
								});
							}
							userPrefs.push(userPreference);
						});

						//default role always has to be MapPortalRole, 
						//as that's the role mapped to all the relevant persmissions 
						//elm_group table : roleName=MapPortalUser
						//Follow this roleId to elm_goruppermissions table and map exact same permissions 
						//to any new role being introduced along with new permissions added.
						let roleId = 8; 
						const roles = roleRes.map((role) => { return parseInt(role.group.groupId); });
						if (roles.length) {
							roleId = Math.max.apply(null, roles); // Gets highest integer in the array
						}
						data.roleId = roleId;									
						this.setState({ isAuthenticated: true, user: data, activeRole: data.defaultrole, userPreferences: userPrefs, preferences: allPrefsRes, standardSets : standardSetRes });
						this.epRoleChangeFn(data.defaultrole);	

						//Fetch the subjects and assign the entity to the user preference
						//We couldn't make this fetch above with the rest because we needed 
						//to know the state/standard set ID
						fetch(SUBJECT + "getSubjects?stateId=" + getUserPreference(this.state.userPreferences, STANDARD_SET_PREF).entity.setid)
						.then(res => res.json())
						.then((subjectRes) => {
							//Update the subject user preference with the proper entity
							let updatedPrefs = this.state.userPreferences.map((userPreference) => {
								if(userPreference.preference.preferenceId === DEFAULT_SUBJECT_PREF){
									for(let i =0; i< subjectRes.length; i++) {
										if(translatePreferenceValue(userPreference) === subjectRes[i].name ) {
											userPreference.entity = subjectRes[i];
											return userPreference;
										}
									}
								}
								return userPreference;
							});
							this.setState({subjects : subjectRes, userPreferences : updatedPrefs}, () => {this.setSessionStorage(data)});
						}); 		

					},
					(fetchErr) => {
						if (sessionStorage) {
							sessionStorage.clear(); 
							this.logoutFn();
						}
					});
				}
				else { // No active session for that uaid. Clear the session and kick them out.
					sessionStorage.clear();
					this.logoutFn();
				}
			},
	
			(err) => { // We got an error back from the server. Clear everything and start from scratch.
				if (sessionStorage) {
					sessionStorage.clear(); 
					this.logoutFn();
				}
			}
		);
	}
	
	// Create a local session stash for this data to prevent logout on refresh
	setSessionStorage(data) {
		if (!sessionStorage) { 
			// No use trying to use session storage if this browser doesn't have it!
			return;
		}
		
		if (data) {
			sessionStorage.setItem('userData', JSON.stringify(data));
			sessionStorage.setItem('epURL', this.state.epURL);
			sessionStorage.setItem('subjects', JSON.stringify(this.state.subjects));
			sessionStorage.setItem('userPreferences', JSON.stringify(this.state.userPreferences));
			sessionStorage.setItem('preferences', JSON.stringify(this.state.preferences));
		}
		else {
			sessionStorage.clear();
		}
	}
	
	// Retrieve local session variables if possible. Returns a promise that rejects on failure or resolves when the data is ready.
	getSessionStorage() {
		return new Promise((resolve, reject) => {
			if (!sessionStorage) { 
				// No use trying to use session storage if this browser doesn't have it!
				reject();
			}
			
			const myDataObj = sessionStorage.getItem('userData');
			if (myDataObj) { // Something was in there!
				const JSONDataObj = JSON.parse(myDataObj);
				
				const storageTimeout = JSONDataObj.storageTimeout ? JSONDataObj.storageTimeout : 0;
				const epURL = sessionStorage.getItem('epURL');
				const subjects = JSON.parse(sessionStorage.getItem('subjects'));
				const userPreferences = JSON.parse(sessionStorage.getItem('userPreferences'));
				const preferences = JSON.parse(sessionStorage.getItem('preferences'));
				
				// 7200000 ms = 2 hours for this session to time out
				if (storageTimeout && (Date.now() > (parseInt(storageTimeout) + 7200000))) {
					sessionStorage.clear();
					reject('Session timeout');
				}
				else {
					this.epRoleChangeFn(JSONDataObj.defaultrole);
					this.setState({
						isAuthenticated: true,
						user: JSONDataObj,
						epURL: epURL,
						subjects : subjects,
						userPreferences : userPreferences,
						preferences: preferences
					}, () => {resolve();});
				}
			}
			else { // Nothing to get!
				reject('No session information found');
			}
		});
	}
	
	// Wipe authentication and user data
	logoutFn() {
		this.setState({
			isAuthenticated: false,
			user: {},
			userAuditId: 'logout'
		}, () => {
			this.setSessionStorage(false);
			window.location.replace(this.state.epURL + '/AART/logout');
		});
	}
	
	// Need a URL for EP in the current environment
	getEPURL() {
		return new Promise((resolve, reject) => {
			fetch(
				LOGIN + 'getEPURL', 
				{
					method: 'GET'
				}
			)
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						epURL: result.url
					}, () => {
						resolve();
					});
				},
				
				(err) => {
					// If this call fails, log everything out and send the user to the 404 page
					this.setState({
						isAuthenticated: false,
						user: {},
						userAuditId: 'logout'
					}, () => {
						reject();
					});
				}
			);
		});
	}
	
	// Initial page view
	componentDidMount() {
		this.getEPURL()
		.then(() => {
			if (!isNaN(parseInt(this.state.userAuditId))) {
				this.newSession();
			} 
			else if (!this.state.isAuthenticated && this.state.userAuditId !== 'logout') { // No login info? See if there's a stored session.
				this.getSessionStorage()
				.catch(() => { // There was nothing there. Redirect to EP.
					window.location.replace(this.state.epURL + '/AART/logout');
				});
			}
		})
		.catch(() => { // Couldn't reach backend
			this.setState({
				isAuthenticated: false, // Will fail generic checks, but also fail the strict check in PageNotFound
				user: {}
			});
		});
	}
	
	render() {
		// Assume the user isn't welcome
		let routeObj = null;
		if (this.state.isAuthenticated) { // Welcome, user!
			// State variables are passed automatically. You will need to pass along functions explicitly
			routeObj = (
				<Route
					component={ (props) => 
						<MapPortalPage 
							toggleSidePanel={this.toggleSidePanel} 
							triggerPrintAction={this.triggerPrintAction}
							logoutFn={this.logoutFn}
							updateMapId = {this.updateMapId}
							updateUserPreferenceOnSubmit={this.updateUserPreferenceOnSubmit}
							{...this.state} 
						/> 
					}
				/>
			);
		}
		
		return (
			<div className="App">
				<Router>
					<Switch>
						<Route 
							exact 
							path='/logout' 
							component= { (props) => <LogoutLanding isAuthenticated={this.state.isAuthenticated} logoutFn={this.logoutFn} /> } 
						/>
						{routeObj}
						<PageNotFound isAuthenticated={this.state.isAuthenticated} />
					</Switch>
				</Router>
			</div>
		);
	}
}
export default App;

// Wrapper for the actual Map Portal
class MapPortalPage extends React.Component {
	render() {
		return (
			<div>
				<NavBar 
					logoutFn={this.props.logoutFn} 
					user={this.props.user}
				/>
				<Switch>
					<Route
						path='/home'
						component={(props) => <Home {...props} epURL={this.props.epURL} />}
					/>
					<Route
						path='/visualizer'
						component={(props) => <MapCanvas
							mapId={this.props.mapId}
							updateMapId={this.props.updateMapId}
							sideOpen={this.props.sideOpen}
							showNodeIds={translatePreferenceValue(getUserPreference(this.props.userPreferences, SHOW_NODE_IDS_PREF))}
							showIndirectEdges={translatePreferenceValue(getUserPreference(this.props.userPreferences, SHOW_INDIRECT_CONNECTIONS_PREF))}
							showMapLegend={translatePreferenceValue(getUserPreference(this.props.userPreferences, SHOW_MAP_LEGEND_PREF))}
							highlightOnHover={translatePreferenceValue(getUserPreference(this.props.userPreferences, SHOW_MAP_HIGHLIGHTING_ON_HOVER_PREF))}
							printAction={this.props.printAction}
							triggerPrintAction={this.props.triggerPrintAction}
							user={this.props.user}
							userPreferences={this.props.userPreferences}
							/>
						}
					/>
					<Route
						path='/preferences'
						component={(props) => <PreferenceMenu
							updateUserPreferenceOnSubmit={this.props.updateUserPreferenceOnSubmit}
							preferences={this.props.preferences}
							userPreferences={this.props.userPreferences}
							{...props}
							/>
						}
					/>
				</Switch>
				<Footer />
			</div>
		);
	}
};

// For when you have somehow been redirected to a page that doesn't exist or that you don't have permission to see
class PageNotFound extends React.Component {
	render() {
		if (this.props.isAuthenticated === false) {
			// If this is the case, we're still waiting for login verification
			return (
				<div style={{ paddingLeft: '20vw' }} >
					<br />
					<h2>Login Processing</h2>
					<p>
						Thank you for using the Map Portal!
					</p>
					<p>
						Please wait while we retrieve your information.
					</p>
				</div>
			);
		}
		
		return (
			<div style={{ paddingLeft: '5vw' }} >
				<br />
				<h2>File Not Found</h2>
				<p>
					We're sorry, but the page you were trying to view could not be found.
				</p>
				<p>
					If you believe you have reached this page in error, please contact your administrator.
				</p>
			</div>
		);
	}
};

// For when you've been logged out, but not redirected back to EP (somehow)
class LogoutLanding extends React.Component {
	render() {
		if (this.props.isAuthenticated) { // Try to make sure this happened.
			this.props.logoutFn();
		}
		
		return (
			<div style={{ paddingLeft: '20vw' }} >
				<br />
				<h2>You are now logged out!</h2>
				<p>
					Thank you for using the Map Portal!
				</p>
				<p>
					For maximum security, please exit your browser now.
				</p>
			</div>
		);
	}
};

class Home extends React.Component {
	render() {
		// Format Quick Links
		let quickLinks = null;
		let quickLinksItems = [];

		// Push items to your Quick Links menu in conditionals here

		if (quickLinksItems.length > 0) {
			quickLinks = (
				<div className='quick-links-box' >
					<span className='quick-links-title' role='heading'>Quick Links</span>
					{quickLinksItems}
				</div>
			);
		}
		
		// Return page to user
		return (
			<div style={{ maxWidth: 1050, marginLeft: 'auto', marginRight: 'auto' }}>
				<p className='home-page-notice'>
				A learning map model is a way of synthesizing research on 
				learning to show the many different ways that students can 
				achieve academic targets. Maps include nodes that show 
				knowledge, skills, and understandings, and connections between 
				the nodes that show the order of learning. These maps were 
				developed through an Enhanced Assessment Grant awarded to the 
				Kansas State Department of Education. Maps were developed for a 
				selection of mathematics and English language arts standards in 
				grades 2-8. In addition to the maps, 2-4 instructional resources 
				per grade and subject were created with the intent to support 
				teacher use of maps. Materials provided are aligned with the 
				Kansas Standards.
				</p>
				<div className='quick-links-box' >
					<span className='quick-links-title' role='heading'>My Profile</span>
					<Link to='/preferences' >
						<img src={myprofile} alt='logo' className='app-logo' />
					</Link>
				</div>
				<br />
				{quickLinks}
				<img src={home_background} alt='' />
				<br />
			</div>
		);
	}
};
