import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NODE_STANDARDS, NODES, MAP_NODES } from '../utility/constants';

class NodeInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parents:[],
            children:[],
            relatedStd:[],
            mapViews:[] 
        }
		
		// Function bindings
        this.closeNodeDetail = this.closeNodeDetail.bind(this)
        this.fetchNodeInfo = this.fetchNodeInfo.bind(this)
    }
    
    fetchNodeInfo(){
        let parents=[];
        let children = [];
		
        //fetching parents and children for a node
        fetch(NODES+'getParentsAndChildren?mapId='+this.props.mapId+'&nodeId=' + this.props.node.nodeId)
        .then(res => res.json())
        .then((result) => {
            if (result.length) {			                        
                //Populate parents and children data from result obtained
                result.forEach(item => {
                    if(item.summary === 'parent') {
                        parents.push(
							<div dangerouslySetInnerHTML={{'__html': item.textID + " : " + item.title + "</br>" }} key={item.nodeId} title={item.shortTitle}></div>
						);
					}
                    else if(item.summary === 'child') {
                        children.push(
							<div dangerouslySetInnerHTML={{'__html': item.textID + " : " + item.title + " </br>" }} key={item.nodeId} title={item.shortTitle}></div>
						);
					}
                });
				
                this.setState({
					children: children,
					parents: parents
				});
				
            }
        });
		
		// fetching standards related to node
		fetch(NODE_STANDARDS+'getStandardsByNode?nodeId='+ this.props.node.nodeId)
		.then(res => res.json())
		.then((result) => {
			if (result && result.NodeStandard) {
				const relatedStd = result.NodeStandard.map((item) => {
					return <div dangerouslySetInnerHTML={{'__html': item.textID + "</br>"}} key={item.standardId} title={item.description}></div>
				});
				
				this.setState({
					relatedStd: relatedStd
				});
			}
		});
		
		// fetch maps related to node
		fetch(MAP_NODES+'selectMapsByNode?nodeId='+ this.props.node.nodeId)
		.then(res => res.json())
		.then((result) => {
			if (result && result.length){
				let maps = result.map(mapNode => {
					let mapObj = null;
					
					if(mapNode.map.mapId!==this.props.mapId){
						mapObj = (
                            <div 
                                dangerouslySetInnerHTML={{'__html': mapNode.map.title + "</br>"}}
								key={mapNode.map.mapId} 
								title={mapNode.map.description}>
							</div>
						);
					}
					
					return mapObj;
				}).filter((mapObj) => mapObj !== null);
				
				this.setState({mapViews:maps});
			}
		});
    }	

    closeNodeDetail(){
        this.props.toggleNodeInfoDiv(false)
    }

    componentDidUpdate(prevprops)
    {
        if (this.props.node.nodeId !== prevprops.node.nodeId) {
            this.fetchNodeInfo();
        }
    }
	
    render(){
        let standards = (this.state.relatedStd && this.state.relatedStd.length) 
			? this.state.relatedStd
            : <div>No standards found</div>;
		
        let mapViews = (this.state.mapViews && this.state.mapViews.length)
            ? this.state.mapViews
            : <div>No Map Views found</div>;
                
        return (
            <div className={'node-Info ' + (this.props.sideOpen ? 'side-open ' : '') + (this.props.nodeInfoOpen ? 'show ' : '') }>
                <Row>
                    <Col  xs={11} className="bot-header">
                       <h6 dangerouslySetInnerHTML={{'__html': this.props.node.textId + " : " + this.props.node.title}}></h6> 
                    </Col>
                    <Col xs={1} className="bot-close-col" onClick={this.closeNodeDetail}  >
                           <h6><FontAwesomeIcon icon={faChevronDown}/></h6> 
                    </Col>
                </Row>
                <div className="scroll">
                <Row dangerouslySetInnerHTML={{'__html': this.props.node.shortTitle}}></Row>
                <hr/>
                <Row>
                    <Col xs={1} >Standards:</Col>
                    <Col xs={3} > {standards} </Col>
                    <Col xs={1} >Map Views:</Col>
                    <Col xs={3} > {mapViews} </Col>
                </Row>
                </div>
            </div>
        );
    }
}
export default NodeInfo;
