import React, { Component } from 'react';
import { GRADEDOMAIN, MAPS, STANDARD, SUBJECT } from '../utility/constants'
import {Button,Card,CardDeck,Row,Col} from "react-bootstrap"
import {getUserPreference, STANDARD_SET_PREF, DEFAULT_SUBJECT_PREF} from '../utility/UserPreferenceUtil.js';

class StandardsTableInfo extends Component {
	constructor(props){
		super(props);
		
		this.state={
			gradeAndDomains:[],
			map_Info:[],
			gradesInfo:[],
			domainInfo:[],
			subjectInfo:[],
			selectedGradeValue:"",
			selectedDomainValue:"",
			selectedSubjectValue:"",
			cluster_Info:[],
			selectedClusterValue:"", 
			showNoMapsMessage: false
		};
		
		// function bindings
		this.populateGradeDomainForASubject = this.populateGradeDomainForASubject.bind(this);
		this.fetchMaps=this.fetchMaps.bind(this);
		this.toggleSubject=this.toggleSubject.bind(this);
		this.setMapId=this.setMapId.bind(this);
		this.domainBoxChange=this.domainBoxChange.bind(this);
		this.gradeBoxChange=this.gradeBoxChange.bind(this);
		this.fetchMapsInformation=this.fetchMapsInformation.bind(this);
		this.clusterBoxChange=this.clusterBoxChange.bind(this);
		this.fetchClusterInformation=this.fetchClusterInformation.bind(this);
		this.fetchSubjectInformation=this.fetchSubjectInformation.bind(this);
	}

	// Mapping between domain>grades and grade>domains
	populateGradeDomainForASubject(subject_checked) {
		// If there's no subject, clear everything out
		if (!subject_checked) {
			this.setState({
				gradeAndDomains: [],
				gradesInfo: [],
				domainInfo: []
			});
			
			return;
		}
		
		fetch(
			GRADEDOMAIN + 
			"getByStateAndSubject?stateId=" + getUserPreference(this.props.userPreferences, STANDARD_SET_PREF).entity.setid +
			"&subjectId=" + subject_checked
		)
		.then(res => res.json())
		.then((result) => {
			let localGradeResult=[];
			let byGradeMapping={
				gradeName:"",
				gradeId:"",
				short:"",
				relatedDomains: []
			};
			result.forEach((gradeDomain) => {
				if (localGradeResult.length>0 && localGradeResult.filter(item=> item.gradeId===gradeDomain.gradesId.gradeId).length>0) {
					localGradeResult.forEach(item => {
						if(item.gradeId===gradeDomain.gradesId.gradeId)
							item.relatedDomains.push(gradeDomain.domainGroupId);
					});	 
				} 
				else {
					byGradeMapping={
						gradeName:gradeDomain.gradesId.name,
						gradeId:gradeDomain.gradesId.gradeId,
						short:gradeDomain.gradesId.shortForm,
						relatedDomains: [
							gradeDomain.domainGroupId
						]
					};
				
					localGradeResult.push(byGradeMapping);
				}
			});

			let localDomainResult = [];
			let byDomainMapping = {
				domainName:"",
				domainId:"",
				short:"",
				relatedGrades: []
			};
			
			result.forEach((gradeDomain) => {
				if(localDomainResult.length>0 && localDomainResult.filter(item=> item.domainName===gradeDomain.domainGroupId.name).length>0) {
					localDomainResult.forEach(item => {
						if(item.domainName===gradeDomain.domainGroupId.name)
							item.relatedGrades.push(gradeDomain.gradesId);
					});
				}	 
				else {
					byDomainMapping={
						domainName:gradeDomain.domainGroupId.name,
						domainId:gradeDomain.domainGroupId.dgId,
						short:gradeDomain.domainGroupId.shortForm,
						relatedGrades: [
							gradeDomain.gradesId
						]
					};
				
					localDomainResult.push(byDomainMapping);
				}	 
			});
		
			this.setState({
				gradeAndDomains: result,
				gradesInfo: localGradeResult,
				domainInfo: localDomainResult
			});
		});
	}

	// Make a selection in subject dropdown
	toggleSubject(e) {
		let subject = parseInt(document.getElementById('subject_select').value);
		this.populateGradeDomainForASubject(subject);

		if (isNaN(subject)) {
			subject = '';
		}

		this.setState({
			selectedSubjectValue: subject,
			selectedGradeValue:'',
			selectedDomainValue:'',
			selectedClusterValue: '',
			showNoMapsMessage:false
		});
	}
	
	// Propogate map id to app.js
	setMapId(ev){
		this.props.updateMapId(ev.currentTarget.id);
	}
	
	fetchMaps() {
		let mapList = [];
		 
		if(this.state.selectedGradeValue==="" && this.state.selectedDomainValue==="")
		{
			const subjectStandardSet = {
				subject : this.state.subjectInfo.filter(s => s.subjectId === this.state.selectedSubjectValue)[0],
				standardSet : getUserPreference(this.props.userPreferences, STANDARD_SET_PREF).entity 
			};

			fetch(
			MAPS+"getMapsByStandardSetAndSubject", 
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(subjectStandardSet)
				}
			)
			.then(res => res.json())
			.then(result => {
				let noMapsFound = false;
				if (result.length===0) {
					noMapsFound = true;
				}
				
				this.setState({
					showNoMapsMessage: noMapsFound,
					map_Info: result
				});	
			});
		} 
		else	
		{
			if(this.state.selectedClusterValue) {
				//Subject + Cluster only selection
				const cVal= this.state.selectedClusterValue;
				mapList.push(cVal +"%");
			}
			else if(this.state.selectedDomainValue) {
				//Subject + Domain only selection
				this.state.cluster_Info.forEach(item=> {
					mapList.push(item.textID+'%');
				});
			}
			else {
				//Grade only selection
				this.state.cluster_Info.forEach(item=> {
					mapList.push(item.textID+'%');
				});
			}

			this.fetchMapsInformation(mapList);
		}
	}

	// Retrieve list of subjects for user's selected standard set (from props)
	fetchSubjectInformation() {
		const standardId = getUserPreference(this.props.userPreferences, STANDARD_SET_PREF).entity.setid;
		
		fetch(
			SUBJECT + 'getSubjects?stateId=' + standardId, { method: 'GET'}
		)
		.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					subjectInfo: result,
					selectedSubjectValue: getUserPreference(this.props.userPreferences, DEFAULT_SUBJECT_PREF).entity.subjectId,
					selectedGradeValue: '',
					selectedDomainValue: '',
					selectedClusterValue: ''
				});
			}
		).then(() => this.toggleSubject());
	}
	
	// Retrieve the Map information
	fetchMapsInformation(mapList) {
		fetch(
			MAPS+"getMapsByDomainAndGrades", 
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					standardSet: getUserPreference(this.props.userPreferences, STANDARD_SET_PREF).entity,
					searchTerms: mapList
				})
			}
		)
		.then(res => res.json())
		.then((result) => {
			let noMapsFound = false;
			if (result.length===0) {
				noMapsFound = true;
			}
			
			this.setState({
				showNoMapsMessage: noMapsFound,
				map_Info: result
			});
		});
	}

	// Retrieve the clusters(standard) based on grade and domain dropdown selection
	fetchClusterInformation() {
		let relevantGrades = this.state.gradesInfo;
		let relevantDomains = this.state.domainInfo;
		let clusters = [];
		const subjVal = this.state.subjectInfo.filter((subject) => subject.subjectId === this.state.selectedSubjectValue)[0].name;
		let domainVal = this.state.selectedDomainValue;
		let gradeVal = this.state.selectedGradeValue;
		
		if (gradeVal) {
			relevantGrades = relevantGrades.filter((item) => item.gradeName === gradeVal);
		}
		
		if (domainVal) {
			relevantDomains = relevantDomains.filter((item) => item.domainName === domainVal);
		}
		
		// Filter out cluster prefixes we don't want
		relevantDomains.forEach((domItem) => {
			const domShort = domItem.short.split('.');
			if (gradeVal) {
				relevantGrades.forEach((gradeItem) => {
					clusters.push(subjVal === 'ELA' ?   domShort[domShort.length-1] + '.' + gradeItem.short + '.%' : gradeItem.short.replace(/[^K0-9]/g, '') + '.' + domShort[domShort.length-1] + ".%" );
				});
			}
			else {
				domItem.relatedGrades.forEach((gradeItem) => {
					clusters.push(subjVal === 'ELA' ?   domShort[domShort.length-1] + '.' + gradeItem.shortForm + '.%' : gradeItem.shortForm.replace(/[^K0-9]/g, '') + '.' + domShort[domShort.length-1] + ".%" );
				});
			}
		});
		
		if (clusters.length) {
			fetch(
				STANDARD+"getClustersByDomainAndGrades", 
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
				body: JSON.stringify(clusters)
				}
			)
			.then(res => res.json())
			.then((result) => {
				const clusterStuff = result.length ? result : [];
				this.setState({
					cluster_Info: clusterStuff
				});
			});
		}
		else {
			this.setState({
				cluster_Info: []
			});
		}
	}

	// Make a selection in grade dropdown
	gradeBoxChange(e) {
		this.setState({
			showNoMapsMessage: false,
			selectedGradeValue: e.target.value,
			selectedClusterValue: ''
		}, () => {
			this.fetchClusterInformation();
		});
	}

	// Make a selection in domain drowpdown
	domainBoxChange(e) {
		this.setState({
			showNoMapsMessage: false,
			selectedDomainValue: e.target.value,
			selectedClusterValue: ''
		}, () => {
			this.fetchClusterInformation();
		});
	}

	// Make a selection in cluster drowpdown
	clusterBoxChange(e) {
		this.setState({
			selectedClusterValue: e.target.value,
			showNoMapsMessage:false
		});
	}
	
	componentDidMount() {
		this.fetchSubjectInformation();
	}
	
	render() {
		let subjectsForSelect = [];
		let gradesForSelect = [];
		let domainsForSelect = [];
		let clustersForSelect = [];
		
		if (this.state.subjectInfo.length) {
			subjectsForSelect = this.state.subjectInfo
				.sort((a, b) => {
					return a.name.localeCompare(b.name);
				})
				.map((subject) => {
					return (
						<option key={subject.subjectId} value={subject.subjectId}>
							{subject.name}
						</option>
					);
				}
			);
		}
		
		if (this.state.gradesInfo.length) {
			gradesForSelect = this.state.gradesInfo
				.map((grade) => {
					return (
						<option key={grade.gradeId} value={grade.name} id={grade.gradeId}>
							{grade.gradeName}
						</option>
					);
				}
			);
		}

		if (this.state.domainInfo.length) {
			domainsForSelect=this.state.domainInfo
				.sort((a, b) => {
					return a.domainName.localeCompare(b.domainName);
				})
				.map((domain) => {
					return (
						<option key={domain.domainId} value={domain.domainName} id={domain.domainId}>
							{domain.domainName}
						</option>
					);
				}
			);
		}
		
		if (this.state.cluster_Info.length) {
			clustersForSelect = this.state.cluster_Info
				.sort((a, b) => {
					return a.textID.localeCompare(b.textID);
				})
				.map((cluster) => {
					return (
						<option key={cluster.standardId} value={cluster.textID} id={cluster.standardId}>
							{cluster.textID}
						</option>
					);
				}
			);
		}

		let cardsList = (this.state.map_Info && (this.state.map_Info.length > 0)) 
			? this.state.map_Info.map((card) => (
				<Col xs={3} key={card.mapId}>
					<Card border="primary" style={{height: '99%', width: '18rem', marginRight:'40rem'}}>
						<Card.Header id={card.mapId} onClick={this.setMapId} style={{cursor: 'pointer'}}>
							<Card.Title >{card.title}</Card.Title>
						</Card.Header>
						<Card.Body style={{overflowY:'hidden'}}>
							<Card.Text >
								{card.description}
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				)) 
			: (this.state.showNoMapsMessage) 
				?
					<label style={{ paddingLeft: '1.70vw'}}>
						Currently maps have not been made available for these selections. Please try other selections.
					</label> 
				: '';

		return (
			<div id="standards">
				<Row >
					<Col>
						<label htmlFor='subject_select' >
							Subject <span style={{ color: 'red' }}>*</span>
						</label>
						<select 
							id="subject_select" 
							className="form-control" 
							onChange={this.toggleSubject} 
							value={this.state.selectedSubjectValue} 
						>
							<option value="">--Select Subject--</option>
							{subjectsForSelect}
						</select>
					</Col>
					<Col>
						<label htmlFor='grade_select' >Grade</label>
						<select 
							onChange={this.gradeBoxChange} 
							id="grade_select" 
							className="form-control" 
							disabled={ this.state.selectedSubjectValue === '' } 
							value={this.state.selectedGradeValue}
						>
							<option value="">--Select Grade--</option>
							{gradesForSelect} 
						</select>
					</Col>
					<Col>
						<Button 
							onClick={this.fetchMaps} 
							style={{marginTop:'25px'}}
							disabled={ !this.state.selectedSubjectValue }
						>
							View Maps
						</Button>
					</Col >
				</Row>
					<br />
				<Row> 
					<Col xs={4}>
						<label htmlFor='domain_select' >Domain</label>
						<select 
							value={this.state.selectedDomainValue}
							onChange={this.domainBoxChange} 
							id="domain_select" 
							className="form-control" 
							disabled={ this.state.selectedSubjectValue === '' } 
						>
							<option	value="">--Select Domain--</option>
							{domainsForSelect}
						</select>
					</Col>
					{ /*
					<Col xs={4}> 
						<label htmlFor='cluster' >Cluster</label>
						<select 
							value={this.state.selectedClusterValue}
							onChange={this.clusterBoxChange} 
							id="cluster" 
							className="form-control" 
							disabled={( this.state.selectedGradeValue === '' && this.state.selectedDomainValue === '' )} 
						> 
							<option value="">--Select Cluster--</option>
							{clustersForSelect}
						</select>
					</Col>
					 */ }
				</Row>
				<br />
				<CardDeck>
					<Row>
						{cardsList}
					</Row>
				</CardDeck>
			</div>
		);
	}
}
export default StandardsTableInfo;
