function parseHtmlEncodedStrings(str) {
    if(str){
        return str.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
            var num = parseInt(numStr, 10) // read num as normal number
            return String.fromCharCode(num)
        });
    }else{
        return str
    }
}
export default parseHtmlEncodedStrings

export const downloadUrilFile = (uri,name) => {
    var link = document.createElement("a")
	link.download = name
	link.href = uri
	document.body.appendChild(link)
	link.click()
    document.body.removeChild(link)
}