import React, { Component } from 'react';
import { MAPS, PRINT_ACTION } from '../utility/constants';
import { Col, Row, Button } from 'react-bootstrap';

class MapInfo extends Component {
    constructor(props) {
        super(props);
		
        this.state = {
            mapInfo: {}
        };
		
		// Function Bindings
        this.getMapInfo = this.getMapInfo.bind(this);
        this.sendPrint = this.sendPrint.bind(this);
    }

    getMapInfo() {
        fetch( MAPS + "getMap?mapId=" + this.props.mapId)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    mapInfo: result
                });
            });
    }

    sendPrint(ev) {
        let clickId = '';
		
        if(ev.currentTarget) {
            switch (ev.currentTarget.id) {
                case PRINT_ACTION.PRINT: 
                    clickId = PRINT_ACTION.PRINT;
                    break;
                case PRINT_ACTION.JPEG: 
                    clickId = PRINT_ACTION.JPEG;
                    break;            
                case PRINT_ACTION.PNG: 
                    clickId = PRINT_ACTION.PNG;               
                    break;                
                default: 
                    clickId = PRINT_ACTION.NONE;
                    break;
            }
			
            this.props.triggerPrintAction(clickId);
        }
    }
	
	componentDidMount() {
		if (this.props.mapId) {
			this.getMapInfo();
		}
	}

    render() {
        return (
            <div>
                <Row>
                    <table id="mapView" className="table">
                        <tbody>
                            <tr>
                                <th>Map Title:</th>
                                <td dangerouslySetInnerHTML={{'__html': this.state.mapInfo.title }}></td>
                            </tr>
                            <tr>
                                <th>Map Description:</th>
                                <td dangerouslySetInnerHTML={{'__html': this.state.mapInfo.description}}></td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
				<hr />
                <Row>
                    <Col xs={12}><label className="sidePanelLabel">Print Visible</label></Col>
                    <Col xs={12}><p>Print a copy of the current map view.</p></Col>
                    <Col xs={12}><Button id="print" variant="primary" onClick={this.sendPrint}>Print Centered Map</Button></Col>
                </Row>
                <Row>
                    <Col xs={12}><label className="sidePanelLabel">Download Map as JPEG Image</label></Col>
                    <Col xs={12}> <p>Download a copy of the current map view as an image.</p></Col>
                    <Col xs={12}> <Button id="jpeg" variant="primary" onClick={this.sendPrint}>Download Map as JPEG Image</Button></Col>
                </Row>
                <Row>
                    <Col xs={12}><label className="sidePanelLabel">Download Map as PNG Image</label></Col>
                    <Col xs={12}><p>Download a copy of the current map view as an image.</p></Col>
                    <Col xs={12}><Button id="png" variant="primary" onClick={this.sendPrint}>Download Map as PNG Image</Button></Col>
                </Row>
            </div>
        );
    }
}
export default MapInfo
