import React from 'react';
import ReactDOM from 'react-dom';
import * as joint from 'rappid';
import dagre from 'dagre';
import 'rappid/dist/rappid.min.css';

const colors = ['grey', 'red', 'blue'];

	/* ***********************************************************************
	 *                                                                       *
	 * The LearningMap component draws a map of the provided nodes, edges,   *
	 * and linkage levels provided to it. The map uses the Rappid library    *
	 * (https://www.jointjs.com/). The required inputs are:                  * 
	 *   nodes: an array of nodes                                            *
	 *   directEdges: an array of solid edges                                *
	 *   indirectEdges: an array of dashed-line edges                        *
	 *   linkageLevels: a object of linkage level groupings indexed by the   * 
	 *      names of the levels                                              *
	 * Please pass an empty array for any inputs that do not apply to the    * 
	 * map you wish to see drawn.                                            *
	 *                                                                       *
	 *********************************************************************** */

class LearningMap extends React.Component {
	constructor(props) {
		super(props);
		
		// Graph properties:
		this.graph = new joint.dia.Graph();
		
		// State management:
		this.state = {};
		
		// Function bindings:
		this.drawMap = this.drawMap.bind(this);
	}
	
	// Initial page load
	componentDidMount() {
		// Wrap the graph contents in a paper element, which is like the View to the graph's Model
		this.paper = new joint.dia.Paper({
			model: this.graph,	 
			position: 'center',
			background: {color: 'transparent'},
			defaultConnectionPoint: { name: 'boundary' },
			restrictTranslate: true, // Keep items from leaving the canvas
			async: true
		});
		
		this.paper.svg.setAttribute('aria-label', 'Learning Map');
		
		if (this.props.interactivity === false) {
			this.paper.setInteractivity(false);
		}
		
		// This wraps the paper in a utility element to enable scrolling, panning, and zooming
		this.paperScroller = new joint.ui.PaperScroller({
			paper: this.paper,
			padding: 10,
			el: ReactDOM.findDOMNode(this.refs.placeholder),
			cursor: 'move',
			autoResizePaper: true
		});
		
		this.drawMap();
	}
	
	// Subsequent changes, like a new set of nodes
	componentDidUpdate(prevProps, prevState) {
		this.drawMap();		
	}
	
	drawMap() {
		// Remove any previous map occupying the graph
		this.graph.clear();
		this.graph.resetCells();
		
		// Add big orange linkage levels
		let linkageLevels = {};
		if (this.props.linkageLevels.length) {
			for (let level in this.props.linkageLevels) {
				let levelRect = new joint.shapes.standard.Rectangle({ 
					id: level ,
					body: {
						fill: 'orange',
						stroke: 'orange',
						rx: 15,
						ry: 15
					}
				});
				
				levelRect.addTo(this.graph);
				
				linkageLevels[level] = levelRect; // For nodes to use later
			}
		}
		
		// Add circular nodes
		if (this.props.nodes.length) {
			let parser = new DOMParser();

			this.props.nodes.forEach((thisNode) => {
				const nodeText = 
					(
						this.props.showNodeIds 
							? (thisNode.nodeKey ? thisNode.nodeKey + '\n' : '')
							: ''
					) 
					+ (thisNode.nodeName ? thisNode.nodeName : '');
				let circle = new joint.shapes.standard.Circle({ 
					id: thisNode.id,
					attrs: {
						label: {
							textWrap: { // Wrap the text to the inside of the circle
								text: parser.parseFromString(nodeText, "text/html").documentElement.textContent,
								width: -10 // Try to keep it from overrunning the sides of the circle, too
							}
						},
						body: {
							stroke: thisNode.nodeColor < colors.length ? colors[thisNode.nodeColor] : 'gray'
						}
					}
				});
				if (thisNode.nodeDesc) {
					circle.attr('root/title', parser.parseFromString(thisNode.nodeDesc, "text/html").documentElement.textContent);
				}
				circle.resize(100, 100);
				
				if (thisNode.linkageLabel) {
					linkageLevels[thisNode.linkageLabel].embed(circle);
				}
				
				circle.addTo(this.graph);
			});
		}
		
		// Add solid edges
		if (this.props.directEdges.length) {
			this.props.directEdges.forEach((thisEdge) => {
				const link = new joint.shapes.standard.Link({
					id: thisEdge.edgeId,
					source: { id: thisEdge.startNode },
					target: { id: thisEdge.endNode },
					smooth:true,
					router: {name:'metro'},
					connector: { name: 'rounded' } // Try to keep link from overlapping with elements
				});

				link.addTo(this.graph);
			});
		}

		// Add dashed-line edges
		if (this.props.indirectEdges.length) {
			this.props.indirectEdges.forEach((thisEdge) => {
				let link = new joint.shapes.standard.Link({
					id: thisEdge.edgeId,
					source: { id: thisEdge.startNode },
					target: { id: thisEdge.endNode },
					smooth: true,
					router: { name: 'metro' }, // Try to keep link from overlapping with elements
					connector: { name: 'rounded' },
					attrs: {
						line: {
							strokeWidth: 2,
							strokeDasharray: '5 5'
						}
					}
				});
								
				link.addTo(this.graph);
			});
		}

		// Arrange the graph
		joint.layout.DirectedGraph.layout(this.graph, {
			nodeSep: 30,
			edgeSep: 5,
			rankSep: 45,
			rankDir: 'TB', // Top-to-Bottom
			marginX: 200, // Keep items from being smashed into sides of the canvas
			marginY: 10,
			graphlib: dagre.graphlib, // These 'Optional' dependencies are actually required. SURPRISE!
			dagre: dagre
		});

		// Add contextmenu to map objects, if applicable
		if (this.props.showContextMenu) {
			//contextMenu For nodes
			this.paper.on('element:contextmenu', (elementView) => {
				let circle = elementView.el;

				//getting handler methods in local variables as context changes once inside joint.ui.contextToolBar 
				let contextMenu =  new joint.ui.ContextToolbar({
					theme: 'modern',
					tools: [
						{ action: 'close', content: "<table style='width:100%'><tr><td style='text-align: left;'>Node:</td><td style='text-align: right;'><b>X</b></td></tr></table>" },
					/*	{ action: '', content: '<div style="text-align: left;">Color:</div>', attrs: {'style': 'float: left; width: 25%; cursor: default;  background: #f6f6f6; '}},
						{ action: 'colorgray', content: 'Gray', attrs: {'style': 'float: left; width: 25%;' + (elementView.model.attributes.attrs.body.stroke === 'gray' ? ' background: #d6c7d2' : '')}},
						{ action: 'colorred', content: 'Red', attrs: {'style': 'float: left; width: 25%;'  + (elementView.model.attributes.attrs.body.stroke === 'red' ? ' background: #d6c7d2' : '')}},
						{ action: 'colorblue', content: 'Blue', attrs: {'style': 'float: left; width: 25%;' + (elementView.model.attributes.attrs.body.stroke === 'blue' ? ' background: #d6c7d2' : '')}},*/
						{ action: 'about', content: '<div style="text-align: left;">About Node</div>' }
					],
					target: circle,
					vertical: true
				});
				
				contextMenu.on('action:close', () => {
					joint.ui.ContextToolbar.close();
				});
				
				contextMenu.on('action:about', () => {					
					this.props.showNodeInfo(elementView.model);
					joint.ui.ContextToolbar.close();
				});

				contextMenu.on('action:colorred', () => {
					this.props.changeNodeColor(elementView.model, 'red').then((res) => {
						joint.ui.ContextToolbar.close();
					});
				});

				contextMenu.on('action:colorblue', () => {
					this.props.changeNodeColor(elementView.model, 'blue').then((res) => {
						joint.ui.ContextToolbar.close();
					});
				});

				contextMenu.on('action:colorgray', () => {
					this.props.changeNodeColor(elementView.model, 'gray').then((res) => {
						joint.ui.ContextToolbar.close();
					});
				});
				
				contextMenu.render();
			});	
			
			//getting nodes in Constants as the context changes in following block of code.		
			const nodes = this.props.nodes;
			
			//contextMenu For Links
			this.paper.on('link:contextmenu', (elementView) => {
				let edge = elementView.el;
				let startNode='';
				let endNode='';
				nodes.forEach(node=>{
					if(node.id===elementView.model.attributes.source.id) {
						startNode = node.nodeName;
					}
					else if(node.id===elementView.model.attributes.target.id) {
						endNode = node.nodeName;
					}
				});

				//getting handler methods in local variables as context changes once inside joint.ui.contextToolBar 
				let contextMenu =  new joint.ui.ContextToolbar({
					theme: 'modern',
					tools: [
						{ action: 'close', content: "<table style='width:100%'><tr><td style='text-align: left;'>Edge Details</td><td style='text-align: right;'><b>X</b></td></tr></table>"  },
						{ action: 'about', content: '<div style="text-align: left;">From:'+startNode+'<br/>To:'+endNode+'</div>' }
					],
					padding:5,
					target: edge,
					vertical: true
				});
				contextMenu.on('action:close', function() {
					joint.ui.ContextToolbar.close();
				});
				contextMenu.render();
			});
		}
		
		// Add element hover highlighting
		if (this.props.highlightOnHover) {
			// Links
			this.paper.on('link:mouseover', (cellView, evt) => { // Hover
				const source = cellView.model.getSourceCell();
				const target = cellView.model.getTargetCell();
				
				// Color Source node
				let sourceColor = 'white';
				switch (source.attributes.attrs.body.stroke) {
					case 'red':
						sourceColor = 'pink';
						break;
					case 'blue':
						sourceColor = 'DeepSkyBlue';
						break;
					default:
						// Uncolored node, I guess? Do nothing.
				}
				
				source.attr('body/fill', sourceColor);
				
				// Color Target node
				let targetColor = 'white';
				switch (target.attributes.attrs.body.stroke) {
					case 'red':
						targetColor = 'pink';
						break;
					case 'blue':
						targetColor = 'DeepSkyBlue';
						break;
					default:
						// Uncolored node, I guess? Do nothing.
				}
				
				target.attr('body/fill', targetColor);
				
				// Color link itself
				cellView.model.attr('line/filter', { 
					name: 'dropShadow', args: {
						dx: 1,
						dy: 1,
						blur: 5,
						color: source.attributes.attrs.body.stroke // TODO: Eventually make this a gradient, if somebody can figure out how
					}
				});
			});
			
			this.paper.on('link:mouseout', (cellView, evt) => { // Unhover
				cellView.model.getSourceCell().attr('body/fill', 'white');
				cellView.model.getTargetCell().attr('body/fill', 'white');
				cellView.model.attr('line/filter', null);
			});
			
			// Circles
			this.paper.on('element:mouseover', (cellView, evt) => { // Hover
				let connectedLinks = this.graph.getConnectedLinks(cellView.model);
				
				// Deal with links where this is the target (incoming)
				connectedLinks.filter((link) => link.attributes.target.id === cellView.model.id)
					.forEach((link) => {
						const source = link.getSourceCell();
						
						// Color Source node
						let sourceColor = 'white';
						switch (source.attributes.attrs.body.stroke) {
							case 'red':
								sourceColor = 'pink';
								break;
							case 'blue':
								sourceColor = 'DeepSkyBlue';
								break;
							default:
								// Uncolored node, I guess? Do nothing.
						}
						
						source.attr('body/fill', sourceColor);
						
						// Color link itself
						link.attr('line/filter', { 
							name: 'dropShadow', args: {
								dx: 1,
								dy: 1,
								blur: 5,
								color: source.attributes.attrs.body.stroke
							}
						});
					}
				);
				
				// Deal with links where this is the source (outgoing)
				connectedLinks.filter((link) => link.attributes.source.id === cellView.model.id)
					.forEach((link) => {
						const target = link.getTargetCell();
				
						// Color Target node
						let targetColor = 'white';
						switch (target.attributes.attrs.body.stroke) {
							case 'red':
								targetColor = 'pink';
								break;
							case 'blue':
								targetColor = 'DeepSkyBlue';
								break;
							default:
								// Uncolored node, I guess? Do nothing.
						}
						
						target.attr('body/fill', targetColor);
						
						// Color link itself
						link.attr('line/filter', { 
							name: 'dropShadow', args: {
								dx: 1,
								dy: 1,
								blur: 5,
								color: target.attributes.attrs.body.stroke 
							}
						});
					});
				}
			);
			
			this.paper.on('element:mouseout', (cellView, evt) => { // Unhover
				let connectedLinks = this.graph.getConnectedLinks(cellView.model);
				
				// Deal with links where this is the target (incoming)
				connectedLinks.filter((link) => link.attributes.target.id === cellView.model.id)
					.forEach((link) => {
						const source = link.getSourceCell();
				
						source.attr('body/fill', 'white');
						
						// Color link itself
						link.attr('line/filter', null);
					}
				);
				
				// Deal with links where this is the source (outgoing)
				connectedLinks.filter((link) => link.attributes.source.id === cellView.model.id)
					.forEach((link) => {
						const target = link.getTargetCell();
						target.attr('body/fill', 'white');
						// Color link itself
						link.attr('line/filter', null);
					}
				);
			}); 
		}

		if (this.props.highlightedNodes !== null) {
			this.props.nodes.forEach((node) => {
				let target = this.graph.getCell(node);

				// Color Source node
				let highlightColor = 'white';
				
				if (this.props.highlightedNodes.includes(node.id)) {
					switch (target.attributes.attrs.body.stroke) {
						case 'red':
							highlightColor = 'pink';
							break;
						case 'blue':
							highlightColor = 'DeepSkyBlue';
							break;
						default:
							// Uncolored node, I guess? Do nothing.
					}
				}

				target.attr('body/fill', highlightColor);
			});
		}

		// Fit contents to the box to start
		this.paperScroller.zoomToFit({ minScale: 0.2, maxScale: 5, useModelGeometry: true});
		this.paperScroller.positionContent('top-left', { useModelGeometry: true });
		
		//common function to handle image download
		function downloadURI(uri, name) {
			var link = document.createElement("a");
			link.download = name;
			link.href = uri;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		  }
		//Print map functionality
		if(this.props.printAction && this.props.printAction === 'print'){			
			
			// force map to render before exporting
			this.paper.dumpViews();
			
			this.paper.print({
				sheet: { width:100, height: 100 },
				sheetUnit: 'pc',
				poster: false,
				margin:{left: 10, top: 50, bottom:10, right: 10},
				marginUnit: 'mm',
				ready : function(pages, send, opt) {
					pages.forEach(page => {
						page.find("circle[stroke='red']").attr('stroke-width', 8);
					});
					send(pages);
				}
			});
			this.props.triggerPrintAction('none');
		}
		//Save as JPEG
		else if(this.props.printAction && this.props.printAction !== 'none'){	
			let fileName = this.props.mapTitle+'map';
			
			const options = {
				padding: 20
			};
			
			// force map to render before exporting
			this.paper.dumpViews();
				
			if(this.props.printAction === 'jpeg'){
				fileName = fileName + '.jpg';
				
				this.paper.toJPEG(
					function(imageData) {
						downloadURI(imageData, fileName);
				},options);
			}else if(this.props.printAction === 'png'){
				fileName = fileName + '.png';
				
				this.paper.toPNG(
					function(imageData) {
						downloadURI(imageData, fileName);
				},options);
			}
			this.props.triggerPrintAction('none');
		}

		// Allow dragging the map to pan
		this.paper.on('blank:pointerdown', (evt) => {
			this.paperScroller.startPanning(evt);
		});

		// If moving stuff is disabled, let users drag on elements, too
		if (this.props.interactivity === false) {
			this.paper.on('element:pointerdown', (cellView, evt) => {
				this.paperScroller.startPanning(evt);
			});
		}

		// Zoom in/out by using mouse wheel over blank space
		this.paper.on('blank:mousewheel', (evt, x, y, delta) => {
			evt.preventDefault();
			this.paperScroller.zoom(delta * 0.2, { min: 0.2, max: 4, grid: 0.2, ox: x, oy: y });
		});

		// Zoom in/out by using mouse wheel over elements
		this.paper.on('element:mousewheel', (cellView, evt, x, y, delta) => {
			evt.preventDefault();
			this.paperScroller.zoom(delta * 0.2, { min: 0.2, max: 4, grid: 0.2, ox: x, oy: y });
		});
	}
	
	render() {
		let zoomButtons = null;
		if (this.props.showZoomButtons) {
			zoomButtons = (
				<span style={{ position: 'absolute', right: 0, top: 0, zIndex: 5 }}>
					<button 
						className='btn btn-primary'
						style={{ width: 35, height: 35, padding: 0, fontSize: 16, fontWeight: 'bolder', border: 'none', margin: 2 }}
						onClick={() => {
							this.paperScroller.zoom(0.2, { min: 0.2, max: 4, grid: 0.2 });
						}}
					>
						+
					</button>
					<br />
					<button 
						className='btn btn-primary'
						style={{ width: 35, height: 35, padding: 0, fontSize: 16, fontWeight: 'bolder', border: 'none', margin: 2 }}
						onClick={() => {
							this.paperScroller.zoom(-0.2, { min: 0.2, max: 4, grid: 0.2 });
						}}
					>
						-
					</button>
				</span>
			);
		}
		
		// Give the map a place to live
		return (	
			<div style={{position:'relative'}} >
				{zoomButtons}
				<div ref='placeholder' className='learning-map' />
			</div>
		);
	}
}
export default LearningMap;