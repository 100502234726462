import React, { Component } from 'react';

class MapLegend extends Component {
	render() {
        return (
			<div id="legend" className={'map-legend ' + (this.props.sideOpen ? 'side-open ' : '') + (this.props.nodeInfoOpen ? 'show ' : '')} >
				<div className='legend-inner'>
					<div className='legend-group'>
						<div className='legend-circle'>
							<svg viewBox='0 0 1.3 1.3' width='3em' xmlns='http://www.w3.org/2000/svg'>
								<circle cx='.65' cy='.65' r='.5' fill='white' stroke='red' strokeWidth='.3'></circle>
							</svg>
						</div>
						<div className='legend-text'>Target<br /> skill</div>
					</div>
					<div className='legend-group'>
						<div className='legend-circle'>
							<svg viewBox='0 0 1.3 1.3' width='3em' xmlns='http://www.w3.org/2000/svg'>
								<circle cx='.65' cy='.65' r='.5' fill='white' stroke='blue' strokeWidth='.3'></circle>
							</svg>
						</div>
						<div className='legend-text'>Related<br /> skill</div>
					</div>
					<div className='legend-group'>
						<div className='legend-img'>
							<svg viewBox='0 0 4 1' height='.75em' width='3em' xmlns='http://www.w3.org/2000/svg'>
								<rect width='3.85' height='.25' x='0' y='0.375'></rect>
								<polygon points='3.5,0 4,0.5 3.5,1'></polygon>
							</svg>  
						</div>
						<div className='legend-text'>Direct<br /> connection</div>
					</div>
					<div className='legend-group'>
						<div className='legend-img'>
							<svg viewBox='0 0 4 1' height='.75em' width='3em' xmlns='http://www.w3.org/2000/svg'>
								<rect width='.60' height='.25' x='0' y='0.375'></rect>
								<rect width='.60' height='.25' x='1' y='0.375'></rect>
								<rect width='.60' height='.25' x='2' y='0.375'></rect>
								<rect width='.60' height='.25' x='3' y='0.375'></rect>
								<polygon points='3.5,0 4,0.5 3.5,1'></polygon>
							</svg> 
						</div>
						<div className='legend-text'>Indirect<br /> connection</div>
					</div>
				</div>
			</div>
        );
    }
}
export default MapLegend;
