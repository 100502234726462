import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                © { new Date().getFullYear() }, University of Kansas.
            </div>
        );
    }
};
export default Footer
