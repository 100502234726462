import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Row, Col} from "react-bootstrap"

class MpCollapseIcon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapseDivId: props.collapseDivId
        }
        this.collapsePanel = this.collapsePanel.bind(this)
    }

    collapsePanel() {
        document.getElementById(this.props.collapseDivId).style.display = "none"
    }

    render() {
        return (
            <Row className="titleRow" id={this.props.rowId}>
                <Col  xs={11}>
                    <h3>{this.props.rowTitle}</h3>
                </Col>
                <Col xs={1} onClick={this.props.toggleSidePanel}>
                    <h4><FontAwesomeIcon icon={this.props.iconType} /></h4>
                </Col>
            </Row>
        )
    }
}

export default MpCollapseIcon