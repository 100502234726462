import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {Row, Col,Button} from "react-bootstrap";
import './PreferenceMenu.css';

class PreferenceMenu extends Component {
	constructor(props){
		super(props);
		
		this.state={
			all_prefs: this.props.preferences,
			changed_pref_details: [],
			userPreferences: this.props.userPreferences
		};
		
		// function bindings
		this.toggleDropDown=this.toggleDropDown.bind(this);
		this.toggleCheckBox=this.toggleCheckBox.bind(this);
	}

	// Break up a string of choices into an array of HTML options
 	splitChoices(choices) {
		return choices.split(',').map(element =>
			<option key={element.trim()} value={element.trim()}>{element}</option>
		);
	}

	// User changed a <select> element's value
	toggleDropDown(e) {		
		//Queue up the changes for the submit button. 
		let selectedDropDown=e.target.selectedIndex;
		let pref_id = parseInt(e.target.id, 10);

		let details={
			preferenceId: pref_id,
			pref_val:""
		};

		//Map the value back to the preference format
		details.pref_val=[...Array(26).keys()].map(i => String.fromCharCode("a".charCodeAt(0) + i))[selectedDropDown];

		// Translate the value from the UI into the model value
		const prefArray = this.state.userPreferences.map(userPreference => {
			if(userPreference.preference.preferenceId === pref_id) {
				userPreference.value = details.pref_val;
			}
				return userPreference;
		});
		
		this.setState({
			userPreferences : prefArray,
			changed_pref_details: this.state.changed_pref_details.concat(details)
		});
	}

	// User changed a checkbox's value
	toggleCheckBox(e) {
		let checked_val=e.target.value;
		let pref_id=parseInt(e.target.id,10);

		let details={
			preferenceId: pref_id,
			pref_val:""
		};

		if(checked_val==="true")
		{
			details.pref_val="f";
		}
		else
		{
			details.pref_val="t";
		}
		
		const prefArray = this.state.userPreferences.map(userPreference => {
			if(userPreference.preference.preferenceId === pref_id) {
				userPreference.value = details.pref_val;
			}
				return userPreference;
		});

		this.setState({
			userPreferences : prefArray,
			changed_pref_details: this.state.changed_pref_details.concat(details)
		});
	}

	//Find the user preference (if it exists) and map it
	//to a choice/checked value. If there is no user preference, fall
	//back to the default value defined in the preference.
	//Please note that for mc types, default values are specified as "a,b,c,..."
	//which map to a choice index of 0,1,2,3... and so on. 
	//What happens when we have more than 26 choices? Shrug...
	mapDefaultVal(preference, userPreference) {
		const value = userPreference ? userPreference.value : preference.defaultValue;
		
		if(preference.formType === 'mc'){
			const alphabetOffset = 25 - ("z".charCodeAt(0) - value.charCodeAt(0));
			
			return preference.choices.split(",").map(x => x.trim())[alphabetOffset];
		}else {
			return value;
		}
	}

	//Iterates over the user preferences in properties
	//and tries to find the corresponding saved user preferences
	getUserPreferenceForPreference(preference) {
		const userPref = this.state.userPreferences.filter((pref) => pref.preference.preferenceId === preference.preferenceId);
		
		if (userPref.length) {
			return userPref[0];
		}
		else {
			return false;
		}
	}

	getPreferenceDefaultValue(preference){
		return this.mapDefaultVal(preference, this.getUserPreferenceForPreference(preference));
	}

	render(){	
	    let dropDownList = this.state.all_prefs.map((item) =>
			item.formType === 'mc' && item.isDisabled === false 
			?		
				<div key={item.programCode} className="mc">
					<label htmlFor={item.preferenceId} >{item.name}</label>
					<select 
						id={item.preferenceId} 
						value={this.getPreferenceDefaultValue(item)} 
						onChange={this.toggleDropDown} 
						className="form-control"
					>			
						{this.splitChoices(item.choices)}
					</select>
				</div>
 			:
				""
		); 
		
		let checkBoxList = this.state.all_prefs.map((item) =>
			item.formType === 'check' && item.isDisabled === false 	
			? 
				<div key ={item.programCode} className="checkbox">					
					<label>
						<input 
							id={item.preferenceId} 
							type="checkbox" 
							onChange={this.toggleCheckBox} 
							value={this.getPreferenceDefaultValue(item) === 't' ? "true" : "false"} 
							defaultChecked={this.getPreferenceDefaultValue(item) === "t"}
						/>
						{item.name}
					</label>
				</div>
			: 
				""
		);  
		
		return (
			<div id="menu-view" className="open">
				<div 
					className="menu-container" 
					id="preferences"
				>			
				    <Row id="title">
				        <Col>
				            <h1>Preferences</h1>
				        </Col>
				    </Row>
				    <Row className="row" id="content">
				        <Col>				                
							{dropDownList}
							{checkBoxList}							
							<Button variant='secondary'>
								<Link 
									to='/home'
									style={{ color: 'white', textDecoration: 'none' }}
								>
									Cancel
								</Link>
							</Button>
							<Button
								onClick={() => { 
									this.props.updateUserPreferenceOnSubmit(this.state.changed_pref_details);
									this.setState({
										changed_pref_details: []
									}, () => {
										this.props.history.push('/home');
									});
								}}
								variant="primary"
								style={{ marginLeft: 10 }}
							>
								Submit
							</Button>
				        </Col>						
					</Row>
				</div>
			</div>
		);
	}
}
export default PreferenceMenu
