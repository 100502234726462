
/**
 * The default values for mc type preferences are expressed 
 * as 'a,b,c,...', which are mapped to '1,2,3...' as indexes into the choices. This function will
 * find that index and return the choice. Default values for type check are either 't' or 'f', 
 * which map to true and false, respectively. 
 * @param {*} userPreference 
 */
export const translatePreferenceValue = (userPreference) => {
    if(userPreference.preference.formType === 'mc'){
        return userPreference.preference.choices.split(",").map(x=>x.trim())[25 - ("z".charCodeAt(0) - (userPreference.value.charCodeAt(0)))];
    }else {
        return userPreference.value === 't' ? true : false;
    }
}

/**
 * Translates a value from an mc or check type preference (a choice index or true/false, respectively)
 * back to the preference internal format (a,b,c... or t/f)
 * @param {*} val 
 * @param {*} userPreference 
 */
export const translateValueToPreference = (val, userPreference) => {
    //value of an mc is expected to be an index into the choices
    if(userPreference.preference.type === 'mc' ){
        return [...Array(26).keys()].map(i => String.fromCharCode("a".charCodeAt(0) + i))[val];
    }else {
        return val ? 't' : 'f'; 
    }
}

/**
 * Returns the index associated with the user preference type
 * @param {*} userPreference 
 */
export const getMcChoiceIndex = (userPreference) => {
    if(userPreference.preference.type === 'mc'){
        return userPreference.preference.choices.split(",")[translatePreferenceValue(userPreference)];
    }else {
        throw new Error("Expected a multiple choice style preference");
    }
}

/**
 * Convenience function for pulling a specific user preference from a collection of them. The preference IDs are available
 * in this file as constants. 
 */
export const getUserPreference = (userPreferences, preferenceId) => {
    return userPreferences.filter((userPreference) => userPreference.preference.preferenceId === preferenceId)[0];
}



//Preference IDs that can be passed to getUserPreference
export const STATE_PREF = 1;
export const DEFAULT_SUBJECT_PREF = 3;
export const SHOW_NODE_IDS_PREF = 6;
export const SHOW_INDIRECT_CONNECTIONS_PREF = 9;
export const HOURGLASS_ZOOM_NODES_ABOVE_PREF = 10;
export const HOURGLASS_ZOOM_NODES_BELOW_PEF = 13;
export const DISABLE_OMNISEARCH_ENTER_KEY_PREF = 16;
export const GRAPH_FONT_PREF = 22;
export const STANDARD_SET_PREF = 25;
export const SHOW_USER_RESOURCES_HELP_PREF = 27;
export const SHOW_FIND_RESOURCE_HELP_PREF = 28;
export const MIN_NODE_FONT_SIZE_PREF = 29;
export const EDIT_MODE_PREF = 30;
export const SHOW_MAP_HIGHLIGHTING_ON_HOVER_PREF = 31;
export const SHOW_MAP_LEGEND_PREF = 32;
