import React, { Component } from 'react';
import SidePanel from './SidePanel';
import {FontAwesomeIcon} from  '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './SideContainer.css';

class SideContainer extends Component {
    render() {
        const innerDiv = this.props.sideOpen
			? (
				<div id="sideNav" className="sidenav">
                    <SidePanel {...this.props} />
                </div>
			)
			: (
				<div id="hamburgerIcon" onClick={this.props.toggleSidePanel}>
					<span className ="menu-icon">
						<FontAwesomeIcon icon={faBars}/>
					</span>
                </div>
			);
		
		return (
            <div className="tabContainer">
                { innerDiv }
            </div>
        );
    }
};
export default SideContainer
