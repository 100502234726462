import React, { Component } from 'react';
import {MAP_RESOURCE} from '../utility/constants';
import parseHtmlEncodedStrings from '../utility/parserHTMLencoding';

class Resources extends Component{
    constructor(props){
        super(props);
        
        this.state = {
            res : []
        };
		
		// Function Bindings
        this.getResources = this.getResources.bind(this);
    }
    
    getResources() {
        fetch(MAP_RESOURCE + "selectResourcesByMap?mapId="+this.props.mapId)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    res: result
                });
            });
    }

	componentDidMount() {
		if (this.props.mapId) {
			this.getResources();
		}
	}

    render(){
        let resList = (this.state.res.length) ? this.state.res.map(item => 
			<tr key={item.resource.resourceId}>
				<td> <a href={item.resource.url} download>{parseHtmlEncodedStrings(item.resource.title)}</a> </td>
				<td> {parseHtmlEncodedStrings(item.resource.description)}</td>
			</tr>) : <tr><td>No Resources Found</td></tr>;
		
        return (
            <div>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                        </tr>
                        {resList}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default Resources
