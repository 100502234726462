import React, { Component } from 'react';
import * as Constants from "../utility/constants";
import { Row, Col } from "react-bootstrap";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import MapInfo from './MapInfo';
import NodeStandards from './NodeStandards';
import NodeTable from './NodeTable';
import Resources from './Resources';
import MpCollapseIcon from '../utility/MpCollapseIcon';

class SidePanel extends Component {
    constructor(props) {
        super(props);
		
        this.state = {
            tabs: [],
            selectedTab: <MapInfo mapId={this.props.mapId} triggerPrintAction={this.props.triggerPrintAction} />,
            activeTab : Constants.MAP_INFO_TAB,
            activeTitle : "Map View Info"
        };
		
		// Function Bindings
        this.getPermissions = this.getPermissions.bind(this);
        this.showDetail = this.showDetail.bind(this);
    }

    componentDidMount() {
        this.getPermissions();
    }

    //function to determine the permissions for logged in user
    getPermissions() {
        fetch( Constants.ROLES_PERMISSION + "getPermissionsByRole?roleId="+this.props.user.roleId)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    tabs: result
                });
            });
    }

    //show the Details related to map based on the tab clicked.
    showDetail(ev) {
        let compClicked = null;
		
        switch (ev.currentTarget.id) {
            case Constants.NODE_TABLE_TAB:
                compClicked =
					<NodeTable
						mapId={this.props.mapId}
						highlightNode={this.props.highlightNode}
						unHighlightNodes={this.props.unHighlightNodes}
					/>;
                break;
            case Constants.RESOURCES_TAB:
                compClicked = <Resources mapId={this.props.mapId} />;
                break;
            case Constants.NODE_STD_TAB:
                compClicked = <NodeStandards mapId={this.props.mapId} 
                        highlightNode={this.props.highlightNode}
						unHighlightNodes={this.props.unHighlightNodes}/>;
                break;
            case Constants.MAP_INFO_TAB:
			default:
                compClicked = <MapInfo mapId={this.props.mapId} triggerPrintAction={this.props.triggerPrintAction} />;
                break;
        }
		
        this.setState({
            selectedTab:compClicked, 
            activeTab:ev.currentTarget.id,
            activeTitle:ev.currentTarget.innerText
        });
    }

    render() {
        const tabsHtml = this.state.tabs.map(item =>
            <Col key={item.permission.permissionId} id={item.permission.programCode} 
                className={this.state.activeTab===item.permission.programCode ? "side-panel-tab panel-open" : "side-panel-tab"} 
                onClick={this.showDetail}>
                <span>{item.permission.name}</span>
            </Col>
        );
		
		// Show or hide the panel based
		const displayStyle = this.props.sideOpen ? 'block' : 'none';
		
        return (
            <div id="side-panel" style={{ display: displayStyle, width: '35vw' }} >
                <div className="side-panel-container">
                    <MpCollapseIcon 
						rowId="titleSidePanel"
						collapseDivId="side-panel"
						toggleSidePanel={this.props.toggleSidePanel}
						rowTitle={this.state.activeTitle}
						iconType={faChevronLeft}
					/>
                    <Row id="tabs">
						<Col className='side-panel-tab' onClick={this.props.clearMapId} >
							<span>Return to Search</span>
						</Col>
                        {tabsHtml}
                    </Row>
                    <div id="detailsDiv" className="container-fluid">
                        {this.state.selectedTab}
                    </div>
                </div>
            </div>
        )
    }
}
export default SidePanel
