import React, { Component } from 'react';
import StandardsTableInfo from './StandardsTableInfo.js';
import './StandardsTable.css';

class StandardsTable extends Component {
  render() {
    return (
		<div className="subject-table">
			<h2> Standards</h2>
			<label style={{marginBottom:'25px'}}>Choose a subject to locate content standards with mapped nodes and resources</label>
			<br />
			<StandardsTableInfo updateMapId={this.props.updateMapId} userPreferences={this.props.userPreferences} />
		</div>
    );
  }
}
export default StandardsTable;
